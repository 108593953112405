<template>
    <div class="box-card" style="text-align: left;">
      <!-- 主要内容 -->
      <el-form :model="detailsForm" ref="addFormRef" label-width="120px" class="demo-ruleForm">
        <div class="title" style="margin-top: 0;">基本信息</div>
        <el-divider></el-divider>
        <el-row>
          <el-col :span="9">
            <el-form-item label="姓名：">{{detailsForm.person_name}}</el-form-item>
            <el-form-item label="所属组织：">{{detailsForm.selected_organize_name ||'无'}}</el-form-item>
            <el-form-item label="性别：">{{detailsForm.sex === 1 ? '女': '男'}}</el-form-item>
            <el-form-item label="手机号：">{{detailsForm.phone || '无'}}</el-form-item>
            <!-- <el-form-item label="证件类型：">{{getWayName('PersonCardType', detailsForm.card_type)}}</el-form-item> -->
            <el-form-item label="出生年月：">{{validDateTime(detailsForm.birthdate) !== '/' ? $moment(detailsForm.birthdate).format("yyyy-MM-DD"):'无'}}
          </el-form-item>
            <el-form-item label="出生地：">{{detailsForm.birthaddres || '无'}}</el-form-item>
            <el-form-item label="毕业院校："> {{detailsForm.graduated_school || '无'}}</el-form-item>
            <el-form-item label="政治面貌：">{{getWayName('PoliticalType', detailsForm.political_outlook )}}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="人员类型：">{{getWayName('RegisterPersonType',detailsForm.person_type)}}</el-form-item>
            <el-form-item label="婚姻状态：">{{getWayName('MaritalStatus',detailsForm.marital_status)}}</el-form-item>
            <el-form-item label="民族：">{{getWayName('NationType',detailsForm.nation)}}</el-form-item>
            <el-form-item label="身份证：">{{detailsForm.card_num || '无'}}</el-form-item>
            <el-form-item label="血型：">{{getWayName('BloodType',detailsForm.blood_type )}}</el-form-item>
            <el-form-item label="现居住地：">{{detailsForm.now_address || '无'}}</el-form-item>
            <el-form-item label="学历：">{{getWayName('EducationType',detailsForm.education)}}</el-form-item>
            <el-form-item label="ID卡号：">{{detailsForm.RFIDCard || '无'}}</el-form-item>
          </el-col>
          <el-col :span="6" style="    text-align: right;">
            <el-image style="width: 200px; height: 300px;" :src="detailsForm.register_pic" :preview-src-list="[detailsForm.register_pic]" fit="cover" :z-index='9000'>
            </el-image>
          </el-col>
        </el-row>
        <template v-if="detailsForm.permission_id != '000000000000000000000000'">
          <div class="title" style="margin-top: 0;">通行权限</div>
          <el-divider></el-divider>
          <el-form-item label="通行权限组：">{{detailsForm.permission_group_name}}</el-form-item>
          <el-form-item label="门禁设备：">
            <el-table class="tablestyle" size="medium" :data="deviceFaceConfigs" height="200" tooltip-effect="dark" style="width: 80%" border stripe>
              <el-table-column prop="device_face_name" label="设备名称"></el-table-column>
              <el-table-column prop="permission_type" label="通行规则">
                <template slot-scope="scope">
                  {{getWayName('permissionType', scope.row.permission_type)}}
                </template>
              </el-table-column>
              <el-table-column label="规则详情">
                <template slot-scope="scope">
                  <span v-if="scope.row.permission_type == 1">无限制</span>
                  <span v-else-if="scope.row.permission_type == 2">{{$moment(scope.row.permission_begintime).format("YYYY-MM-DD HH:mm:ss")}}&nbsp;&nbsp;至&nbsp;&nbsp;{{$moment(scope.row.permission_endtime).format("YYYY-MM-DD HH:mm:ss")}}</span>
                  <span v-else-if="scope.row.permission_type == 3">
                    {{$moment(scope.row.permission_beginday).format("YYYY-MM-DD")}}&nbsp;&nbsp;至&nbsp;&nbsp;{{$moment(scope.row.permission_endday).format("YYYY-MM-DD")}}&nbsp;&nbsp;&nbsp;&nbsp;
                    {{$moment(scope.row.permission_begintime).format('HH:mm:ss')}}&nbsp;&nbsp;至&nbsp;&nbsp;{{$moment(scope.row.permission_endtime).format('HH:mm:ss')}}
                  </span>
                  <span v-else-if="scope.row.permission_type == 4">{{scope.row.permission_count}}&nbsp;次</span>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </template>
        <template v-if="detailsForm.person_type === 2">
          <div class="title" style="margin-top: 0;">【APP】巡检</div>
          <el-divider></el-divider>
          <el-form-item label="视频巡检">
          <el-select popper-class="my-select" v-model="selected_scene_name" placeholder="请选择监控场景" filterable
            style="width:30%">
            <el-option class="setstyle" disabled clearable :value="selected_scene_name">
              <el-tree :data="sceneTreeList" :default-checked-keys="innerSceneId" :props="defaultProps"
                ref="sceneTree" node-key="Id" :highlight-current="true" default-expand-all :render-content="renderContent">
              </el-tree>
            </el-option>
          </el-select>
        </el-form-item>
        </template>
      </el-form>
    </div>
  </template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { getPersonDetail, getPermissionGroupDetail } from '@/api/administrative'
import { getBindScene } from '@/api/monitoring'

export default {
  props: ['id'],
  data () {
    return {
      detailsForm: {
        register_pic: '', // 注册头像 (必填)
        person_name: '', // 人员姓名 (必填)
        sex: 1, // 人员性别  女 = 0, 男 = 1
        phone: '', // 手机号码
        card_type: 1, // 证件类型 身份证=1
        card_num: '', // 证件号码
        birthdate: '', // 出生日期
        birthaddres: '', // 出生地
        marital_status: 1, // 婚姻状况   未婚 = 1,已婚 = 2
        nation: 1, // 民族  汉族 = 1,回族=2,蒙古族=3
        blood_type: 2, // 血型 O型 = 1, A型=2, B型=3,AB型=4,其它=5
        now_address: '', // 现居住地
        graduated_school: '', // 毕业学校
        education: 1, // 学历大专 = 1,本科=2,研究生=3,博士=4,其它=5
        political_outlook: 1, // 政治面貌
        organize_id: '', // 主组织id (必填)
        selected_organize_id: '', // 所属组织id(必填)
        permission_id: '', // 通行权限组id
        is_black: false, // 是否为黑名单
        person_type: 1// 普通人员 = 1, 巡检人员 = 2,维修人员 = 3
      },
      deviceFaceConfigs: [], // 门禁设备列表
      filterSceneList: [], // 巡检人员选中场景列表
      innerSceneId: [],
      selected_scene_name: '',
      sceneTreeList: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id'
        // multiple: true,可以选择多个选项
        // checkStrictly: true, // 可以选择父类名称进行选择
      }

    }
  },
  created () {
    this.fnGetPersonDetail()
  },
  mounted () { },
  computed: {
    ...mapGetters(['getOptions', 'getWayName'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    async fnGetPersonDetail () {
      const res = await getPersonDetail({ person_id: this.id })
      console.log('res', res)
      if (res && res.Code === 200) {
        this.detailsForm = res.Data.personinfo
        if (res.Data.personinfo.permission_id !== '000000000000000000000000') {
          this.fnGetPermissionGroupDetail(res.Data.personinfo.permission_id)
        }
        if (res.Data.personinfo.person_type === 2) {
          if (res.Data.personinfo.selected_scene) {
            this.filterSceneList = res.Data.personinfo.selected_scene
            this.filterSceneList.forEach(item => {
              this.innerSceneId = [...this.innerSceneId, ...item.category_id]
            })
            this.selected_scene_name = `已选中${this.filterSceneList.length}个视频场景`
          }
          // 获取全部场景树
          this.fnGetBindScene()
        }
      };
    },
    // 获取通行组门禁设备列表
    async fnGetPermissionGroupDetail (id) {
      console.log(id)
      const res = await getPermissionGroupDetail({ per_groupid: id })
      console.log(res)
      if (res && res.Code === 200) {
        this.deviceFaceConfigs = res.Data.device_face_configs
        this.detailsForm.permission_group_name = res.Data.permission_group_name
      }
    },
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    },
    // 获取场景树
    async fnGetBindScene () {
      const res = await getBindScene()
      if (res && res.Code === 200) {
        this.sceneTreeList = res.Data
      }
    },
    renderContent (h, { node, data, store }) {
      if (node.checked || node.indeterminate) {
        return (
          <span>{node.data.name}</span>
        )
      } else {
        // 查询当前节点id在父节点id的索引
        const nowNode = node
        const parent = node.parent
        const children = parent.childNodes
        const delIndex = children.findIndex(item => {
          return item.id === nowNode.id
        })
        children.splice(delIndex, 1)
      }
    }
  }

}

</script>
  <style scoped lang="less">
  .title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #000;
    margin-top: 50px;
  }
  .demo-ruleForm {
    max-height: 650px;
    overflow-y: auto;
    overflow-x: hidden;
    /deep/ .el-form-item {
      margin-bottom: 0;
    }
  }
  .tablestyle {
    /deep/ .el-table__body-wrapper {
      max-height: 180px;
    }
  }
  </style>
