<template>
  <!-- 人员管理 --- 组织成员 -->
  <div style="margin-top: 20px;">
    <div class="box-card" style="text-align: left;">
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="组织：">
          <el-cascader popper-class="my-cascader" filterable v-model="queryForm.organize_id" clearable checkStrictly
            :props="defaultProps" placeholder="请选择组织..." :options="organizationSeleteList" @change="onSubmit()">
          </el-cascader>
          <!-- <el-select popper-class="my-select" filterable clearable v-model="queryForm.organize_id" placeholder="请选择组织" @change="onSubmit">
              <el-option v-for="item in organizationSeleteList" :key="item._id" :label="item.organize_name" :value="item._id"></el-option>
            </el-select> -->
        </el-form-item>
        <el-form-item label="姓名：">
          <el-input maxlength="15" v-model="queryForm.person_name" @keyup.enter.native="onSubmit()" placeholder="请输入姓名"
            @input="(e) => (queryForm.person_name = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input maxlength="11" v-model="queryForm.phone" @keyup.enter.native="onSubmit()" placeholder="请输入手机号"
            @input="(e) => (queryForm.phone = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item label="人员类型：">
          <el-select popper-class="my-select" filterable clearable v-model="queryForm.person_type" placeholder="请选择类型"
            @clear="queryForm.person_type = null" @change="onSubmit()">
            <el-option v-for="item in getOptions('RegisterPersonType')" :key="item.value" :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="来源：">
        <el-select popper-class="my-select" v-model="queryForm.person_source" placeholder="请选择来源" style="width:100%" @change="onSubmit()" clearable filterable  @clear="queryForm.person_source = null">
          <el-option v-for="item in getOptions('RegisterPersonSource')" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="组织成员">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="setting" icon="shebeipeizhi" @click="joinBlackList"
              v-if="isShowBtn('632ab0c4b81f000093002524')">加入黑名单</lbButton>
            <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('632ab0c4b81f000093002522')">删除
            </lbButton>
            <lbButton icon="xinzeng" @click="handelOrganizationMember()"
              v-if="!isAdmin && isShowBtn('632ab0c4b81f000093002521')">新增</lbButton>
          </div>
        </template>
        <!-- 主要内容 -->
        <div>
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
              <template slot-scope="{row}" slot="register_pic">
                <el-image fit="cover" :src="row.register_pic" :preview-src-list="[row.register_pic]">
                </el-image>
            </template>
             <template slot-scope="{row}" slot="person_type">
              <span>{{ getWayName("RegisterPersonType", row.person_type) }} </span>
            </template>
            <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看"
                  @click="lookOrganizationMember(row._id)"></lbButton>
                <lbButton type="warning" icon="bianji" hint="编辑"
                  @click="handelOrganizationMember(row._id)"
                  v-if="isShowBtn('632ab0c4b81f000093002523')"></lbButton>
            </template>
          </avue-crud> -->
          <el-table class="tablestyle" ref="multipleTable" :data="tableData" tooltip-effect="dark" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
            <el-table-column label="图像" >
              <template slot-scope="scope">
                <el-image fit="cover" :src="scope.row.register_pic" :preview-src-list="[scope.row.register_pic]">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="person_name" label="姓名" ></el-table-column>
            <el-table-column prop="phone" label="手机号" ></el-table-column>
            <el-table-column prop="selected_organize_name" label="所属组织" show-overflow-tooltip min-width="250"></el-table-column>
            <el-table-column prop="person_type" label="人员类型" >
              <template slot-scope="scope">
                <span>{{ getWayName("RegisterPersonType", scope.row.person_type) }} </span>
              </template>
            </el-table-column>
            <el-table-column prop="person_source" label="来源" >
              <template slot-scope="scope">
                <span>{{ getWayName("RegisterPersonSource", scope.row.person_source) }} </span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template slot-scope="scope">
                {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <lbButton type="succes" icon="chakan" hint="查看"
                  @click="lookOrganizationMember(scope.row._id)"></lbButton>
                <lbButton type="warning" icon="bianji" hint="编辑"
                  @click="handelOrganizationMember(scope.row._id)"
                  v-if="isShowBtn('632ab0c4b81f000093002523')"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </le-card>
    </div>
    <!-- 新增编辑组织成员 -->
    <lebo-dialog :title="dialogOrganizationMemberId?'编辑组织成员':'新增组织成员'" :isShow="showAddOrganizationMember" width="65%" @close="showAddOrganizationMember = false" footerSlot>
      <addOrganizationMember v-if="showAddOrganizationMember" :id="dialogOrganizationMemberId" @closeDialog="closeOrganizationMember"></addOrganizationMember>
    </lebo-dialog>
    <!-- 查看组织成员 -->
    <lebo-dialog title="查看组织成员" :isShow="showLookOrganizationMember" width="65%" @close="showLookOrganizationMember = false" footerSlot closeOnClickModal>
      <lookOrganizationMember v-if="showLookOrganizationMember" :id="dialogOrganizationMemberId" @closeDialog="closeOrganizationMember"></lookOrganizationMember>
    </lebo-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { getPersonnelInfoPageList, deletePerson, addByBlack, getOrganizatioTreeList } from '@/api/administrative'
import addOrganizationMember from './components/addOrganizationMember/index.vue'
import lookOrganizationMember from './components/lookOrganizationMember/index.vue'
export default {
  components: { addOrganizationMember, lookOrganizationMember },
  data () {
    return {
      // 查询表单
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        organize_id: '',
        person_name: '',
        phone: '',
        card_num: '',
        person_type: null, // 0：全部   普通人员 = 1, 巡检人员 = 2,维修人员 = 3
        person_source: null // 来源（0：全部，枚举：RegisterPersonSource）
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      // 组织下拉列表
      organizationSeleteList: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id',
        // multiple: true,可以选择多个选项
        checkStrictly: true // 可以选择父类名称进行选择
      },
      showAddOrganizationMember: false,
      showLookOrganizationMember: false,
      dialogOrganizationMemberId: '',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '图像',
            prop: 'register_pic'
          }, {
            label: '姓名',
            prop: 'person_name'
          }, {
            label: '手机号',
            prop: 'phone',
            slot: true
          }, {
            label: '所属组织',
            prop: 'selected_organize_name',
            width: 350,
            slot: true
          }, {
            label: '人员类型',
            prop: 'person_type',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetOrganizationSeleteList()
    this.fnGetPersonnelInfoPageList()
    console.log('isAdmin-----', this.isAdmin)
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']), ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])

  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 获取组织下拉列表
    async fnGetOrganizationSeleteList () {
      const res = await getOrganizatioTreeList()
      this.organizationSeleteList = res && res.Code === 200 ? this.filterChildren(res.Data.concat()) : []
    },
    // 获取组织成员数据列表
    async fnGetPersonnelInfoPageList () {
      const res = await getPersonnelInfoPageList({
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        organize_id: this.queryForm.organize_id && this.queryForm.organize_id.length > 0 ? this.queryForm.organize_id[this.queryForm.organize_id.length - 1] : null,
        person_name: this.queryForm.person_name,
        phone: this.queryForm.phone,
        card_num: this.queryForm.card_num,
        person_type: this.queryForm.person_type ? this.queryForm.person_type : 0,
        person_source: this.queryForm.person_source ? this.queryForm.person_source : 0
      })
      this.tableData = res && res.Code === 200 ? res.Data.DataList : []
      this.total = res && res.Code === 200 ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetPersonnelInfoPageList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetPersonnelInfoPageList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetPersonnelInfoPageList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      // console.log(`当前页: ${val}`);
      this.fnGetPersonnelInfoPageList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetPersonnelInfoPageList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的人员, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // console.log('aaaa');
          // this.$msg.success('删除成功!');
          var idArr = []
          this.multipleSelection.forEach((item) => {
            idArr.push(item._id)
          })
          this.fndeletePerson({ _ids: idArr })
        }).catch(() => {
          // console.log('catch---');
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的人员！')
      }
    },
    async fndeletePerson (obj) {
      const res = await deletePerson(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetPersonnelInfoPageList()
    },
    // 加入黑名单
    joinBlackList () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将选中的人员加入黑名单中, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var idArr = []
          this.multipleSelection.forEach((item) => {
            idArr.push(item._id)
          })
          this.addByBlack({ _ids: idArr })
        }).catch(() => {
          this.$msg.info('已取消加入黑名单')
        })
      } else {
        this.$msg.warning('请选择要加入黑名单的人员！')
      }
    },
    async addByBlack (obj) {
      const res = await addByBlack(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetPersonnelInfoPageList()
    },
    // 新增编辑组织成员
    handelOrganizationMember (id) {
      this.dialogOrganizationMemberId = id
      this.showAddOrganizationMember = true
    },
    closeOrganizationMember () {
      this.showAddOrganizationMember = false
      this.fnGetPersonnelInfoPageList()
    },
    lookOrganizationMember (id) {
      this.dialogOrganizationMemberId = id
      this.showLookOrganizationMember = true
    }
  }
}
</script>

<style scoped lang="less">.el-select {
  width: 100%;
}

.el-image {
  width: 55px;
  height: 55px;
  /deep/.el-image__error{
    background-image: url('~@/assets/img/noMessage/noPeopleInLight.png');
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    font-size:0;
  }
}</style>
